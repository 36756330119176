import { useRequestURL } from '#imports'

import type {
  BasePageContentAPI,
  BlockType,
} from '@backmarket/http-api/src/api-specs-content'
import type { EmailCollectorApiProps } from '@backmarket/http-api/src/api-specs-content/models/email-collector'
import type {
  SectionBannerApiProps,
  SectionBannerBackgroundApiProps,
} from '@backmarket/http-api/src/api-specs-content/models/section-banner'
import type { BackgroundProps } from '@backmarket/nuxt-layer-cms/Background.types'
import type { EmailCollectorProps } from '@backmarket/nuxt-layer-cms/EmailCollector.types'
import type { SectionBannerProps } from '@backmarket/nuxt-layer-cms/SectionBanner.types'
import { insertIf } from '@backmarket/utils/collection/insertIf'

import { buildPropsWithCorrectLinks } from '../helpers/link/buildPropsWithCorrectLinks'

function mapToBackground(
  props: SectionBannerBackgroundApiProps,
): BackgroundProps {
  if (
    props.sectionBannerImageBackground &&
    'image' in props.sectionBannerImageBackground
  )
    return {
      background: {
        image: props.sectionBannerImageBackground.image,
        mode: props.sectionBannerImageBackground.mode,
      },
    }

  if (props.sectionBannerMonochromeBackground?.theme) {
    return {
      background: {
        theme: props.sectionBannerMonochromeBackground.theme,
      },
    }
  }

  return { background: undefined }
}

function mapEmailCollectorProps(
  apiProps: EmailCollectorApiProps,
): EmailCollectorProps {
  const {
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
    ...rest
  } = apiProps
  const { background } = mapToBackground({
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
  })

  return {
    ...rest,
    background,
  }
}

function mapSectionBannerProps(
  apiProps: SectionBannerApiProps,
): SectionBannerProps {
  const {
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
    ...rest
  } = apiProps
  const { background } = mapToBackground({
    sectionBannerImageBackground,
    sectionBannerMonochromeBackground,
  })

  return {
    ...rest,
    background,
  }
}

// TODO: map BlockType to ApiProps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Mapper = (params: any) => any
const blockPropsMappers: Partial<Record<BlockType, Mapper>> = {
  EmailCollector: mapEmailCollectorProps,
  SectionBanner: mapSectionBannerProps,
}
// TODO COM-1345 remove pageName and pageType
export function useFormatContentResponse() {
  const currentUrl = useRequestURL()

  function formatContentResponse<P extends BasePageContentAPI>(response: P) {
    const pageName = 'name' in response ? response.name : response.pageName
    const pageType = 'type' in response ? response.type : response.pageType
    const parentPageInfosPageName =
      response.parentPageInfos &&
      ('name' in response.parentPageInfos
        ? response.parentPageInfos.name
        : response.parentPageInfos.pageName)

    return {
      ...response,
      pageName,
      pageType,
      ...insertIf(!!parentPageInfosPageName, {
        parentPageInfos: {
          ...response.parentPageInfos,
          pageName: parentPageInfosPageName,
        },
      }),
      blocks: response.blocks.map((block) => {
        const propsWithCorrectLinks = buildPropsWithCorrectLinks({
          props: block.props,
          currentUrl,
        })

        const blockMapper = blockPropsMappers[block.type]

        if (blockMapper) {
          const mappedProps = blockMapper(propsWithCorrectLinks)

          return {
            ...block,
            props: mappedProps,
          }
        }

        return {
          ...block,
          props: propsWithCorrectLinks,
        }
      }),
    }
  }

  return formatContentResponse
}
